<template>
    <div class="modal-send-contact" @click="refresh">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="`매칭상대 선택하기`" />
            <i class="material-icons" @click.stop="$emit('close')">close</i>
        </div>
        <div class="body">
            <p class="m-b-16">약속 잡기를 위한 상대를 골라주세요.</p>
            <div class="items">
                <div
                    class="item"
                    :class="{ selected: item.selected, inactive: !active(item).active, line: active(item).cancelLine }"
                    v-for="item in items"
                    :key="item.id"
                    @click.stop="onClickItem(item)"
                >
                    <span>{{ item.user.name }}</span>
                    <span class="border">ㅣ</span>
                    <span>{{ $options.filters.asAge(item.user.profile.birthday) }}</span>
                    <span class="border">ㅣ</span>
                    <span>{{ (item.user.profile.company[0] || {}).job_category_name }}</span>
                    <span> {{ (item.user.profile.company[0] || {}).job_name }}</span>
                    <span class="border">ㅣ</span>
                    <span>{{ item.enabled ? '' : '호감박스에서 삭제' }}</span>
                    <div :class="{ line: active(item).cancelLine }" />
                </div>
            </div>

            <div v-if="!items.length" class="items-empty">매칭할 회원이 없습니다.</div>
        </div>
        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close', 0)" class="btn-default">
                    {{ 'CANCEL' | translate }}
                </button>
                <button @click.stop="onClickConfirm" ref="send" class="btn-primary" :class="{ disabled }">
                    {{ 'CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import chatFloatingService from '@/services/chat-floating'
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalCreateSchedule',
    props: ['options'],
    data: () => ({
        items: [],
        selected: null,
    }),
    computed: {
        disabled() {
            if (!this.items.length) return true

            return !this.items.some(i => i.selected)
        },
        chat() {
            return this.options.chat
        },
        isMale() {
            return this.chat.user.gender === 0
        },
    },
    methods: {
        active(item) {
            // 남자일떄: 여자가 스케줄을 보내주었을 때
            if (this.isMale) {
                const active = item.schedule && item.schedule.schedule_status === 'entered_type'
                const cancelLine =
                    item.schedule &&
                    !['start_a', 'entered_date', 'entered_type'].includes((item.schedule || {}).schedule_status)
                return {
                    active,
                    cancelLine,
                }
            }
            // 여자일떄: 스케줄이 없을 때
            else {
                return {
                    active: item.schedule === null,
                    cancelLine: item.schedule !== null,
                }
            }
        },
        onClickItem(item) {
            if (!this.active(item).active || this.active(item).cancelLine) {
                return
            }

            this.items = this.items.map(i => ({
                ...i,
                selected: i.id === item.id,
            }))
            this.selected = this.items.find(i => i.selected)
        },
        refresh() {
            this.items = this.items.map(i => ({
                ...i,
                selected: false,
            }))
            this.selected = null
        },
        async onClickConfirm() {
            try {
                const aUser = this.selected.schedule ? this.selected.user.id : this.chat.user.id
                const bUser = this.selected.schedule ? this.chat.user.id : this.selected.user.id
                const { res } = await scheduleService.initSchedule({
                    a_user_id: aUser,
                    b_user_id: bUser,
                })
                if (res === 'fail') {
                    this.$toast.error('이미 약속잡기가 진행 중이에요')
                }
                this.$emit('close')
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
    async mounted() {
        let data = await chatFloatingService.scheduleModal(this.options.chat.id)
        data = data.filter(d => d)
        this.items = data.map(d => ({
            ...d,
            selected: false,
        }))
    },
}
</script>

<style scoped lang="scss">
.title {
    margin-bottom: 0;
}
.border {
    margin: 0 8px;
    color: $grey-04;
    @include f-regular;
}
.item {
    position: relative;
    width: 100%;
    padding: 8px 16px;
    color: black;
    margin-bottom: 12px;
    border: 1px solid $grey-02;
    border-radius: 4px;
    @include f-bold;

    &.inactive {
        background: $grey-03;
    }
    &.selected {
        background: $purple-primary;
        color: white;
    }
    &.line {
        background: white;
    }
    .line {
        background: black;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
    }
}
.items-empty {
    width: 100%;
    height: 86px;
    color: $grey-04;
    font-size: 14px;
    @include center;
}
</style>
